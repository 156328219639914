.services__container {
    display: grid;
    grid-template-columns: repeat(3, 250px);
    gap: 3rem;
    column-gap: 1.8rem;
    justify-content: center;
}

.services__content {
    position: relative;
    background-color: var(--box-color);
    padding: 6rem 0 2rem 2.5rem;
    border-radius: .25rem;
    border: 1px solid var(--box-color);
    height: fit-content;
    transition: var(--transition);
}

.services__content:hover {
    background: transparent;
    border-color: var(--skin-color);
    cursor: default;
}

.services__icon {
    display: block;
    font-size: 2rem;
    color: var(--skin-color);
    margin-bottom: var(--mb-1);
}

.services__title {
    font-size: var(--h3-font-size);
    margin-bottom: var(--mb-1);
    font-weight: var(--font-medium);
}

.services__button {
    color: var(--skin-color);
    font-size: var(--small-font-size);
    display: flex;
    align-items: center;
    column-gap: .25rem;
    cursor: pointer;
}

.services__button-icon {
    font-size: 1rem;
    transition: .3s;
    margin-right: 4px;
    position: relative;
    top: 1px;
}

.services__button-icon:hover {
    transform: translateX(.25rem)
}


/* ======= SERVICES MODAL ======= */

.services__modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 1rem;
    z-index: var(--z-modal);
    opacity: 0;
    visibility: hidden;
    transition: .3s;
}

.services__modal-content {
    width: 500px;
    position: relative;
    background-color: var(--box-color);
    padding: 4.5rem 2.5rem 2.5rem;
    border-radius: .5rem;
}

.services__modal-close {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    font-size: 1.5rem;
    color: var(--skin-color);
    cursor: pointer;
}

.services__modal-title,
.services__modal- {
    text-align: center;
}

.services__modal-title {
    font-size: var(--h3-font-size);
    font-family: var(--font-medium);
    margin-bottom: var(--mb-1);
}

.services__modal-description {
    font-size: var(--small-font-size);
    font-family: 0 3.5rem;
    margin-bottom: var(--mb-2);
}

.service__modal-services {
    display: grid;
    row-gap: var(--mb-0-75);
    align-items: center;
    column-gap: .5rem;
}

.service__modal-services li {
    display: flex;
    gap: 1rem;
    margin-bottom: 0.8rem;
}

.services__modal-icon {
    color: var(--skin-color);
    font-size: 1.2rem;
}

.services__modal-info {
    font-size: var(--small-font-size);
}


/* ======= ACTIVE MODAL ======= */

.active-modal {
    opacity: 1;
    visibility: visible;
}


/* ========= MEDIA QUARIES (MEDIUM DEVICES) ========= */

@media screen and (max-width: 1024px) {
    .services__container {
        grid-template-columns: 1fr 1fr;
        gap: 2rem;
    }
    .service {
        height: auto;
    }
}


/* ========= MEDIA QUARIES (SMALL DEVICES) ========= */

@media screen and (max-width: 600px) {
    .services__container {
        grid-template-columns: 1fr;
        gap: 1.5rem;
    }
}