.portfolio__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;
}

.portfolio__filters {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: .75rem;
    margin-bottom: 2rem;
}

.portfolio__tab {
    cursor: pointer;
    color: var(--color-text);
    padding: .25rem .75rem;
    font-weight: var(--font-bold);
    border-radius: .5rem;
    background: var(--color-bg);
    font-size: 1rem;
}

.active__portfolio {
    background-color: var(--skin-color);
    color: var(--color-text);
}

.portfolio__item {
    background: var(--box-color);
    padding: 1.3rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
}

.portfolio__item:hover {
    border-color: var(--skin-color);
    background: transparent;
}

.portfolio__item-image {
    border-radius: 1.5rem;
    overflow: hidden;
}

.portfolio__item-title {
    margin: 1.2rem 0 2rem;
    font-size: var(--normal-font-size);
    font-weight: var(--font-medium);
}

.portfolio__item-cta {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
}

.portfolio__item-details {
    display: none;
}


/* ========= PORTFOLIO BUTTON DEMO ========= */

.portfolio__button {
    color: var(--skin-color);
    font-size: var(--small-font-size);
    display: flex;
    align-items: center;
    column-gap: .25rem;
    cursor: pointer;
    font-weight: var(--font-bold);
}

.portfolio__button-icon {
    font-size: 1rem;
}

.portfolio__button-icon:hover {
    transform: translateX(.25rem);
}

.portfolio__button-social {
    color: var(--skin-color);
    font-size: var(--normal-font-size);
}


/* ========= PORTFOLIO POPUP - MODAL ========= */

.portfolio__popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 1rem;
    z-index: var(--z-modal);
    opacity: 0;
    visibility: hidden;
    transition: .3s;
}

.portfolio__popup.open {
    opacity: 1;
    visibility: visible;
}

.portfolio__popup-inner {
    background-color: var(--box-color);
    width: 900px;
    border-radius: 1rem;
    padding: 2.5rem;
    position: relative;
}

.portfolio__popup-content {
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    column-gap: 3rem;
    display: grid;
}

.portfolio__popup-close {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    font-size: 1.5rem;
    color: var(--skin-color);
    cursor: pointer;
}

.pp__thumbnail img {
    border-radius: 1.5rem;
}

.portfolio__popup-subtitle {
    font-size: var(--smaller-font-size);
    margin-bottom: var(--mb-0-25);
}

.portfolio__popup-subtitle span {
    font-size: var(--smaller-font-size);
    margin-bottom: var(--mb-0-25);
    color: var(--skin-color);
    font-weight: var(--font-bold);
}

.details__title {
    font-size: var(--h3-font-size);
    font-weight: var(--font-medium);
    margin-bottom: var(--mb-1);
}

.details__description {
    font-size: var(--small-font-size);
    margin-bottom: var(--mb-2);
}

.details__info li {
    margin-bottom: var(--mb-0-75);
    text-transform: capitalize;
    font-size: var(--small-font-size);
}

.details__info li:last-child {
    margin-bottom: 0;
}

.details__info li span {
    font-weight: var(--font-bold);
}

.details__info li a {
    text-transform: lowercase;
    color: var(--skin-color);
}


/* ========= NAVIGATION ========= */

.navigation {
    display: none;
}

.portfolio__content-caption {
    position: absolute;
    left: .1rem;
    bottom: -2rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: var(--smaller-font-size);
    color: var(--color-white);
    font-weight: var(--font-bold);
    z-index: -1;
}

.prev__item,
.next__item {
    position: absolute;
    height: 40px;
    width: 40px;
    border-color: var(--color-white);
    border-radius: 50%;
    text-align: center;
    cursor: pointer;
    top: 15rem;
    font-size: var(--h1-font-size);
    margin-top: -20px;
    z-index: 110;
    transition: all 0.3s ease;
}

.prev__item {
    left: -5rem;
}

.next__item {
    right: -5rem;
}

.prev__item:hover,
.next__item:hover {
    transform: scale(1.1);
}


/* ========= COMMING SOON ========= */

.comming__soon {
    font-family: 'Roboto, sans-serif';
    text-align: center;
}


/* ========= MEDIA QUARIES (MEDIUM DEVICES) ========= */

@media screen and (max-width: 1024px) {
    .portfolio__container {
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem;
    }
}


/* ========= MEDIA QUARIES (SMALL DEVICES) ========= */

@media screen and (max-width: 600px) {
    .portfolio__container {
        grid-template-columns: 1fr;
        gap: 1rem;
    }
}