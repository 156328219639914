    @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');
    * {
        margin: 0;
        padding: 0;
        border: 0;
        outline: 0;
        box-sizing: border-box;
        list-style: none;
        text-decoration: none;
    }
    
     :root {
        /*===== Colors =====*/
        --hue-color: 242;
        /* HSL Color Mode */
        --skin-color: #00a78e;
        --color-text: hsl(var(--hue-color), 8%, 95%);
        --color-white: hsl(var(--hue-color), 8%, 85%);
        --color-bg: hsl(var(--hue-color), 19%, 5%);
        --box-color: hsl(var(--hue-color), 14%, 10%);
        --scroll-bar-color: hsl(var(--hue-color), 12%, 38%);
        --scroll-thumb-color: hsl(var(--hue-color), 12%, 26%);
        /*===== Font and Typography =====*/
        --body-font: 'Poppins', sans-serif;
        /* .5rem = 8px, 1rem = 16px, 1.5rem = 24px ... */
        --biggest-font-size: 3rem;
        --h1-font-size: 2.25rem;
        --h2-font-size: 1.5rem;
        --h3-font-size: 1.25rem;
        --normal-font-size: 1rem;
        --small-font-size: .875rem;
        --smaller-font-size: .813rem;
        /*===== Font Weight =====*/
        --font-medium: 500;
        --font-bold: 600;
        /*===== Margenes Bottom =====*/
        --mb-0-25: .25rem;
        --mb-0-5: .5rem;
        --mb-0-75: .75rem;
        --mb-1: 1rem;
        --mb-1-5: 1.5rem;
        --mb-2: 2rem;
        --mb-2-5: 2.5rem;
        --mb-3: 3rem;
        /*===== Z Index =====*/
        --z-fixed: 10;
        --z-modal: 100;
        /*===== Container Margenes =====*/
        --container-width-lg: 75%;
        --container-width-md: 86%;
        --container-width-sm: 90%;
    }
    
    html {
        scroll-behavior: smooth;
    }
    
     ::-webkit-scrollbar {
        display: none;
    }
    
    body {
        font-family: 'Poppins', sans-serif;
        background: var(--color-bg);
        color: var(--color-white);
        line-height: 1.7;
    }
    /* ========= GENERAL STYLE ========= */
    
    .container {
        width: var(--container-width-lg);
        margin: 0 auto;
    }
    
    h1,
    h2,
    h3,
    h4,
    h5 {
        font-weight: var(--font-bold);
    }
    
    h1 {
        font-size: 2.5rem;
    }
    
    section {
        margin-top: 8rem;
    }
    
    section>h2,
    section>h5 {
        text-align: center;
        color: var(--skin-color);
    }
    
    section>h2 {
        color: var(--color-white);
        margin-bottom: 3rem;
    }
    
    .text-light {
        color: var(--color-light);
    }
    
    a {
        color: var(--color-white);
        transition: var(--transition);
    }
    
    a:hover {
        color: var(--skin-color);
    }
    
    .btn {
        width: max-content;
        display: inline-block;
        color: var(--color-white);
        padding: 0.75rem 1.2rem;
        border-radius: 0.4rem;
        cursor: pointer;
        font-weight: var(--font-medium);
        border: 1px solid var(--skin-color);
        transition: var(--transition);
        z-index: 1;
    }
    
    .btn:hover {
        background: var(--color-white);
        color: var(--color-bg);
        border-color: transparent;
    }
    
    .btn-primary {
        background: var(--skin-color);
        color: var(--color-white);
        font-size: 0.9rem;
    }
    
    .btn-primary:hover {
        background: transparent;
        border-color: var(--skin-color);
        color: var(--color-white);
    }
    
    img {
        display: block;
        width: 100%;
        object-fit: cover;
    }
    /* ========= MEDIA QUARIES (MEDIUM DEVICES) ========= */
    
    @media screen and (max-width: 1024px) {
        .container {
            width: var(--container-width-md);
        }
        section {
            margin-top: 6rem;
        }
    }
    /* ========= MEDIA QUARIES (SMALL DEVICES) ========= */
    
    @media screen and (max-width: 600px) {
        .container {
            width: var(--container-width-sm);
        }
        section>h2 {
            margin-bottom: 2rem;
        }
    }